@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import "src/app/styles/variables.scss";

/* General */

*,::after,::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: #303030;
  margin: 0;
  padding-left:10px;
  padding-right:10px;
}

a {
  color: white; // smg was white
  text-decoration: none;
  transition: all 0.3s ease-out 0s;
}

.container {
  width: 100%;
  // margin-right: auto;
  // margin-left: auto;
}

.padding-top-60 {
  padding-top: 60px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.hidden {display: none;}

/* Loading */

.cssloader {
  padding-top: calc(45vh - 25px);
}

.sh1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #354952 transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
}

.sh2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent  transparent #df1a54 transparent ;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }

  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.lt {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  margin: 30px auto;
  text-align: center;
  font-weight: 100;
  letter-spacing: 10px;
}

/* media query */

/*xs*/
@media (max-width: $breakpoint-xs) {
  .body{
  padding-right: 0;
  padding-left: 0;
}
  .container {
    max-width: $breakpoint-xs;

  }
}

/*sm*/
@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
  .container {
    max-width: 720px;
    // padding-right: 10px;
    // padding-left: 10px;
    padding-right: 0;
    padding-left: 0;
  }
}

/*md*/
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
  .container {
    max-width: 960px;
    // padding-right: 15px;
    // padding-left: 15px;
  }
}

// lg media query with variables
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
  .container {
    max-width: 1140px;
    // padding-right: 10px;
    // padding-left: 10px;
  }
}

// xl media query
@media (min-width: $breakpoint-lg) {
  .container {
     max-width: 1900px;  //smg was 1140
    // padding-right: 10px;
    // padding-left: 10px;
  }
}
