$header-height: 3.5rem;

$h1-font-size: 1.5rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1rem;
$normal-font-size: .938rem;
$small-font-size: .813rem;
$smaller-font-size: .75rem;

/*========== Font weight ==========*/
$font-medium: 500;
$font-semi-bold: 600;

/*========== Margins Bottom ==========*/
/*.5rem = 8px | 1rem = 16px ...*/
$mb-0-25: .25rem;
$mb-0-5: .5rem;
$mb-1: 1rem;
$mb-1-5: 1.5rem;
$mb-2: 2rem;
$mb-2-5: 2.5rem;

$z-fixed: 10;

// media queries
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
