@use '@angular/material' as mat;
@include mat.core();

$theme-primary: mat.define-palette(mat.$orange-palette);
$theme-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);

@mixin palette($theme) {
  $primary-color: mat.get-color-from-palette($theme, 500);
  ::selection { background-color: $primary-color; }
  .colored-hover:hover {color: $primary-color;}
  .swiper-pagination-bullet {background: $primary-color;}
  .activated-route {border-color: $primary-color !important;}
  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {border-color: $primary-color;}
}

@include palette($theme-primary);

$theme: mat.define-dark-theme((
  color: (primary: $theme-primary, accent: $theme-accent),
  typography: mat.define-typography-config()
));
@include mat.all-component-themes($theme);


.orange-theme {
  $theme-primary: mat.define-palette(mat.$orange-palette);
  @include palette($theme-primary);

  $theme: mat.define-dark-theme((
    color: (primary: $theme-primary, accent: $theme-accent),
    background-color:black,
    typography: mat.define-typography-config()
  ));
  @include mat.all-component-colors($theme);
}

.blue-theme {
  $theme-primary: mat.define-palette(mat.$blue-palette);
  @include palette($theme-primary);

  $theme: mat.define-dark-theme((
    color: (primary: $theme-primary, accent: $theme-accent),
    typography: mat.define-typography-config()
  ));
  @include mat.all-component-colors($theme);
}

.green-theme {
  $theme-primary: mat.define-palette(mat.$green-palette);
  @include palette($theme-primary);

  $theme: mat.define-light-theme((
    color: (primary: $theme-primary, accent: $theme-accent),
    typography: mat.define-typography-config()
  ));
  @include mat.all-component-colors($theme);
}

// Dialogo

.dialog-title-container {
  cursor: move;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  a {float: right; color: white}
  h2 {margin: 0; padding: 0; font-size: 1.1rem; color: #fff; display: flex; align-items: center;}
}

.mdc-dialog-container {
  padding: 0 !important;
}

.mdc-dialog__content {
  padding: 0 !important;
  margin: 0 !important;
}
